import { captureRemixErrorBoundaryError } from "@sentry/remix";
import {
  json,
  Links,
  Meta,
  Scripts,
  ScrollRestoration,
  useActionData,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import "./tailwind.css";
import "./globals.css";
import { ActionFunctionArgs, MetaFunction } from "@remix-run/node";
import { Input } from "./components/ui/input";
import { Button } from "./components/ui/button";
import { Toaster } from "./components/ui/toaster";
import { z } from "zod";

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <Toaster />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const meta: MetaFunction = () => {
  return [
    { title: "LingoQuest" },
    { description: "Join the waitlist for LingoQuest" },
  ];
};

export const loader = async () => {
  const MAILING_LIST_USER = process.env.LISTMONK_ADMIN_USER as string;
  const MAILING_LIST_PASS = process.env.LISTMONK_ADMIN_PASSWORD as string;

  const mailingLists = await fetch(
    `http://listmonk:9000/api/lists?minimal=true&per_page=all`,
    {
      headers: {
        Authorization: `Basic ${btoa(
          `${MAILING_LIST_USER}:${MAILING_LIST_PASS}`
        )}`,
      },
    }
  );

  const formId = (await mailingLists.json()).data.results.find(
    (list: { name: string }) => list.name === "Default list"
  ).uuid as string;

  return json({ formId });
};

export const ErrorBoundary = () => {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);

  return <div>Something went wrong</div>;
};

export default function Index() {
  const { formId } = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();

  return (
    <div className="h-dvh w-full bg-neutral-950 relative flex flex-col items-center justify-center antialiased">
      <div className="max-w-2xl mx-auto p-4 flex flex-col space-y-4 md:space-y-8">
        <img
          src="/logo-no-background.png"
          alt="LingoQuest Logo"
          className="max-w-xl w-full mx-auto mb-8 md:mb-16"
        />

        <h2 className="relative z-10 text-lg md:text-4xl bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-600  text-center font-sans font-bold">
          Join the waitlist
        </h2>

        <p className="text-neutral-500 max-w-lg mx-auto text-sm text-center relative z-10">
          Are you ready to revolutionize the way you learn languages? Our app is
          designed to elevate your reading, listening, and vocabulary skills
          like never before. Whether you&apos;re just starting or looking to
          refine your language abilities, our app offers an immersive and
          interactive experience tailored to your needs.
        </p>

        <p className="relative z-10 text-lg md:text-3xl bg-clip-text text-transparent bg-gradient-to-b from-neutral-200 to-neutral-600  text-center font-sans font-bold">
          Be the First to Experience It!
        </p>

        <p className="text-neutral-500 max-w-lg mx-auto text-sm text-center relative z-10">
          Don&apos;t miss out on transforming your language learning journey.
          Sign up now to join the waiting list and be the first to know when we
          launch. Add your email below and get ready to learn smarter, not
          harder.
        </p>

        {!formId && (
          <p className="text-red-600 max-w-lg mx-auto text-sm text-center relative z-10">
            Something went wrong. Form id is not loaded. Please try again later.
          </p>
        )}

        {!actionData && (
          <form
            method="post"
            action="/"
            className="flex w-full items-center space-x-2"
          >
            <input
              id="d83eb"
              type="checkbox"
              name="l"
              hidden
              defaultChecked
              value={formId}
            />
            <input type="hidden" name="nonce" />
            <Input
              required
              name="email"
              type="email"
              placeholder="Email"
              className="border border-neutral-800 focus:ring-2 focus:ring-teal-500 bg-neutral-950 placeholder:text-neutral-700 text-neutral-200"
            />
            <Button type="submit">Subscribe</Button>
          </form>
        )}
        {actionData && actionData.success && (
          <p className="text-green-600 max-w-lg mx-auto text-sm text-center relative z-10">
            You have been added to the waiting list. please check your email to
            confirm your subscription. 🎉
          </p>
        )}
      </div>
    </div>
  );
}

export const action = async ({ request }: ActionFunctionArgs) => {
  const body = await request.formData();

  z.object({
    email: z.string().email(),
    l: z.string(),
  }).safeParseAsync(body);

  await fetch(`http://listmonk:9000/subscription/form`, {
    method: "POST",
    body,
  });

  return json({ success: true });
};
